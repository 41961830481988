.aboutHclass{
    margin-bottom: 30px;
    
  }

  .aboutCardclass{
    width: 21rem;
    height: 17rem;
    color: white;
    /* margin-right: 20px; */
    padding-left: 10px;
    padding-right: 7px;
    margin-bottom: 15px;
    /* padding: 3px; */
    }
    .conta{
        display: contents;
    }
    #ida1{
        height: 17rem;
        background-color: #222;
    }
    #aboutClassContainer{
        width: 800px;
        overflow-x: hidden;
        padding-top: 15px;
        padding-left: 50px;
        padding-right: 50px;
    }
    .aboutpics{
        float: left;
        height: 100px;
        width: 100px;
    }
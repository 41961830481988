.motionstyle2{
    overflow: hidden;
}
.Link{
    display: contents;
}
.CardImage{
    background-color:black;
    position: relative;
    align-items: center;
    /* padding-top: 50%; */
    
    /* border-style: solid; */
    transition: all 1s;
}

/* .CardImage p:hover{
    opacity: 1;
} */

.CardImage:hover{
    /* opacity: 0.5; */
    filter: brightness(50%);

    /* transition: .5 ease-in; */
}
.CardImage a{
    /* margin: auto; */
    opacity: 0;
    display: inline-block;
    /* position: relative; */
    height: 40px; 
    width: 40px;
    transition: all 1s; 
}
.CardImage:hover a{
    opacity: 1;
    
    /* position: relative; */
}
.Cardclass2{
    width: 15rem;
    color: black;
    margin-right: 15px;
    padding: 3px;
    }
    .Cardclass2:hover{
        /* box-shadow: 5px 5px whitesmoke; */
        box-shadow: 0 4px 8px 0 rgb(255, 255, 255), 0 6px 10px 0 rgba(207, 201, 201, 0.956);
        transition: 0.4s;
    }

    #outliner{
        height: 350px;
        margin-left: 15px;
        
    }
    #outliner2{
        height: 350px;
        
        
    }
    #outliner3{
        height: 350px;
        
    }
    .eyeball{
        background: black;
        /* margin-right: 15px; */
        border-radius: 100%;
        color:white;
        fill: white;
      }
      .eyeball:hover{
        background: white;
        color: black; 
        fill: black;
    }
    .tesstdiv{
        display: block;
        margin: auto;
        align-items: center;
        /* width: 80%; */
        line-height:  2.5;
        /* float: left; */
        padding: 20px 0;
        text-align: center;
    
    }
    .workGit{
        margin-right: 50px;
    } 


  
  .aboutpic {

    display: inline-block;
    border-radius: 10%; 
    margin-right: 20px;
    text-align: center;

    /* position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto; */
    
  }
  .homediv{
    height: 500px;
    width: 500px;
    
    /* float: right; */
    padding-right: 20px;
    padding-left: 30px;
    /* background-color: brown; */
    text-align: left;
    /* border-style: solid; */
    overflow: hidden;
    
  }
  #fullHome{
    /* background-color: blueviolet; */
    display: flex;
    /* border: 1px solid red */
  }
  #imgclassH{
    float: left;
    height: 50px;
    width: 50px;
}
#phome1{
    /* float: right; */
    font-family: "Times New Roman";
    /* font-size: larger; */
    font-size: 25px;
    margin-top : 11px;
    margin-left: 3em;
    /* border-style: solid; */
}
#phome2{
  font-family: "Times New Roman";
  font-size: 25px;
  /* background-color: aqua; */
  /* margin: 9.8em; */
  margin-top: 5.5em;
  margin-left: 7em;

}
#phome3{
  font-family: "Times New Roman";
  font-size: 25px;
  margin-top: 6.0em;
  margin-left: 3em;
  
}
#divmotion1{
    width: fit-content;
    /* background-color: green; */
}
/* align-items: flex-end;
display: flex;
flex: 1 1;
height: 50%;
justify-content: flex-end;
position: relative; */
#voiddiv{
    height: 400px;
    width: 500px;
    padding: 15px;
    margin-right: 20px;
    /* text-align: left; */
    /* border-style: solid; */
}
#voiddiv p{
  font-family: "Times New Roman";
  font-size: larger;
  line-height: 2.0;
}
/* after this copyewd stuff */
#present{
  font-family: "Times New Roman"
}

.contact{
    width: 600px;
    float: right;
}
#postcontact{
    /* border-style: solid; */
    float: right;
    display: none;
    padding: 70px;
    text-align: center;
}
#texta{
    width: 600px;
}
#div0{
    float: left;
    /* border-style: solid ; */
    width: 500px;
    margin-right: 110px;
    margin-top: 30px;
    display: block;
    text-align: center;
}
#div1{
    float: left;
}
#div2{
    background-color: bisque;
    float: right;
}
#dt1{
    float: left;
}
#dt2{
    float: right;
}
/*
after this copyed stuff */

/* .soc{
  margin-top: 30px;
  display: block;
  list-style: none;
  text-align: center;
}
li.soc{
    display: inline-block
} */
a,svg{
    display: block
}
#div0 a{
    display: inline-block;
    position: relative; 
    height: 50px; 
    /* width: 50px;  */
}
svg{
    height: 100%;
    width: 100%;
}
.icon{
    margin-right: 15px;
    background: black;
    border-radius: 100%;
    color: white;
    fill: white;
  
}
.icon:hover{
    background: white; 
    color: #007BB6;
    fill: #007BB6;
}
.github{
  background: white;
  margin-right: 15px;
  border-radius: 100%;
  color: black;
  fill: black;
}  
.github:hover{
    background: #6441a5;
    color: white; 
    fill: white;
}
.faceB{
    background: black;
    border-radius: 100%;
    color: white;
    fill: white;

}
.faceB:hover{
    background-color: white;
    color: #3B579D;
    fill: #3B579D
}
 #stlyeoverride{
    height: auto;
    width: auto;
    text-align: left;
 }



.motionstyle{
    /* height: 600px; */
    /* padding-top: 15%; */
    /* background-color: blueviolet; */
    overflow: hidden;
}

  .skillgroup{
    text-align: center;
    /* background-color: brown; */
    
    
    /* background-color: aquamarine; */
  }
  .skillgroup p{
    color: whitesmoke;
  }
  .skillgroup Figure{
    margin-left:  25px;
    margin-right: 25px;
  }
  #DATA{
    height: 50px;
    width:  50px;
  }


.skillcard{
    width: 15rem;
    color: black;
    margin-right: 15px;
    margin-left: 15px;
    padding: 3px;
    /* animation: fadeInBottom 0.2s ease-in-out;
    animation-delay: 2s;
    animation-fill-mode: both; */

    /* transition: all .2s ease-in-out; */
    
    }
    .skillcard:hover{
      /* background-color: aqua; */

    }
    .mStyle{
        background-color: coral;
        font-style: italic;
        /* animation: fadeInBottom 0.2s ease-in-out;
        animation-delay: 2s;
        animation-fill-mode: both; */
    }

    @keyframes fadeInBottom {
        from {
            opacity: 0;
            transform: translateY(100px);
        }
        to { opacity: 1 }
    }
    .imgclassS{
        height: 50px;
        width: 50px;
    }
    .imgclassS2{
      height: 38px;
      width: 40px;
    }

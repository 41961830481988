

.App {
  text-align: center;
  /* overflow: hidden; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body{
  max-width: 2000px;
  overflow-x: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h3 {
  font-size: 5em;
  text-align: center;
  color: #fff;
  font-weight: 700;
}


.section{
  height: 100vh;
  align-items: center;
  /* justify-content: center; */
  color: #fff;
}

/* .fp-section {
  text-align: center;
} */

/* this is for changing the nav dots */

/* #fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
  background-color: green;
} */

#fp-nav ul li a span, 
.fp-slidesNav ul li a span {
    background: rgb(247, 246, 246)!important;
}
/* #fp-nav ul li a span, 
.fp-slidesNav ul li a span {
    background: green;
} */



/* .Cardclass{
    width: 15rem;
    height: 20rem;
    color: black;
    margin-right: 20px;
    padding: 3px;
    transition: transform .2s;
    transition: all .2s ease-in-out;
    
    } */

    .homeward{
      border-style: solid;
      border-width: 2px;
    }

    @keyframes fadeInRight {
      from {
        opacity: 0;
        transform: translatex(100px);
      }
      to {
        opacity: 1;
        
      }
    }​
    /* .Cardclass p{
      transition: all .2s ease-in-out;
    }
    .Cardclass:hover p{
      padding: 1px;
      transform: scale(1.02);
    }
    .Cardclass:hover h1{
      padding: 1px;
      transform: scale(1.02);
    } */
    .img{
      max-width: 100%;
      max-height: 50%;
    }
    .txt{
      color: white;
      font-size: x-large;
    }
    #copyr{
      font-size: smaller;
      position: absolute;
      top: 94%;
      right: 2%;
      
  }
  #homefix{
    height: 100vh;
  }
  #aboutfix{
    height: 100vh;
  }
  #skillfix{
    height: 100vh;
  }
  #workfix{
    height: 100vh;
  }
  #contactfix{
    height: 100vh;
  }
  #fix{
    height: 100vh;
  }